import React, { createContext, useEffect, useState } from "react";

type officeContextType = {
  currentOffice: number | undefined;
  setCurrentOffice: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const officeContextState = {
  currentOffice: undefined,
  setCurrentOffice: () => "",
};

const officeContext = createContext<officeContextType>(officeContextState);

const OfficeProvider = (props: { children: React.ReactNode }) => {
  const [currentOffice, setCurrentOffice] = useState<number | undefined>();
  let localStorage: Storage;

  if (typeof window !== "undefined") {
    localStorage = window.localStorage;
  }

  useEffect(() => {
    if (currentOffice) {
      localStorage.setItem("currentOffice", currentOffice.toString());
    }
  }, [currentOffice]);

  return (
    <officeContext.Provider value={{ currentOffice, setCurrentOffice }}>
      {props.children}
    </officeContext.Provider>
  );
};

export { officeContext, OfficeProvider };
